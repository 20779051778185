import { CaseArrestsFilterState } from '@/api/apiPlatform/CaseArrestsFilterState';
import { CaseArrestsFilterStates } from '@/api/apiPlatform/CaseArrestsFilterStates';

/**
 * Map of labels to translate a ArrestStateFilter's arrest states into a human readable form.
 */
export const ArrestStateToLabel: Record<CaseArrestsFilterState, string> = {
  [CaseArrestsFilterStates.ACTIVE]: 'arrest.state.active',
  [CaseArrestsFilterStates.CREATING]: 'arrest.state.creating',
  [CaseArrestsFilterStates.DRAFT]: 'arrest.state.draft',
  [CaseArrestsFilterStates.LEGACY]: 'arrest.state.legacy',
  [CaseArrestsFilterStates.REMOVED]: 'arrest.state.removed',
  [CaseArrestsFilterStates.REMOVING]: 'arrest.state.removing',
  [CaseArrestsFilterStates.UPDATING]: 'arrest.state.updating',
  [CaseArrestsFilterStates.ERROR_ON_REQUEST]: 'arrest.state.error_on_request',
  [CaseArrestsFilterStates.ERROR_FROM_BANK]: 'arrest.state.error_from_bank',
};
