import { ArrestState } from '@/static/enums/arrestState';

/**
 * Separate 'error' state enum that handles finding cases where there has been
 * some error while creating/updating/removing an arrest. This is not actually
 * an ArrestState used in arrest's lifecycle, but is a filter-specific term and
 * used in CaseArrestsFilter.php!
 */
enum CaseArrestsFilterState {
  // The request from TAPAIS2 to E-Arest or Pensionikeskus failed for some reason.
  ERROR_ON_REQUEST = 'error_on_request',
  // Bank from E-Arest replied with some reason why the arrest could not be set or updated.
  ERROR_FROM_BANK = 'error_from_bank',
}

/**
 * Combined "enum" of values from ArrestState and CaseArrestsFilterState.
 */
export const CaseArrestsFilterStates = {
  ...ArrestState,
  ...CaseArrestsFilterState,
};
