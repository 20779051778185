import { ArrestStatus } from '@/static/enums/arrestStatus';
import { CaseArrestsFilterState } from '@/api/apiPlatform/CaseArrestsFilterState';
import { CaseArrestsFilterStates } from '@/api/apiPlatform/CaseArrestsFilterStates';

/**
 * Object that maps EArest status to corresponding
 * arrest state of ArrestStateFilter.
 */
const EArrestStatusToArrestState: Record<ArrestStatus, CaseArrestsFilterState> =
  {
    // EArrest statuses => ACTIVE state.
    [ArrestStatus.ACTIVE]: CaseArrestsFilterStates.ACTIVE,
    [ArrestStatus.ARREST_FULFILLED]: CaseArrestsFilterStates.ACTIVE,
    // Legacy status for earrests, before we implemented our own combined ArrestState.
    [ArrestStatus.ACTIVE_LEGACY]: CaseArrestsFilterStates.ACTIVE,
    // TAPAIS arrests fall back to 'active' state when UPDATING of arrest is successful.
    // So EArrest statuses for this status should correspond to 'active' state.
    [ArrestStatus.CHANGE_CHANGED]: CaseArrestsFilterStates.ACTIVE,
    // Following EArrest statuses are marked as 'active' in TAPAIS2, because
    // there is no action that user can take to "fix" these statuses. For example
    // if person is not client, then if he or she becomes a client EArrest itself
    // automatically will attempt to arrest given person again, and then the
    // if then the applying of arrests fails for other reasons, then it would be
    // classified as "there was error from TAPAIS2 side when applying the arrest".
    [ArrestStatus.NO_ACCOUNT]: CaseArrestsFilterStates.ACTIVE,
    [ArrestStatus.NOT_CLIENT]: CaseArrestsFilterStates.ACTIVE,
    [ArrestStatus.CHANGE_FAILED_CANCELLED]: CaseArrestsFilterStates.ACTIVE,
    [ArrestStatus.CHANGE_FAILED_FULFILLED]: CaseArrestsFilterStates.ACTIVE,
    [ArrestStatus.CHANGE_FAILED_OTHER]: CaseArrestsFilterStates.ACTIVE,
    // EArrest statuses => CREATING state.
    [ArrestStatus.IN_ARREST]: CaseArrestsFilterStates.CREATING,
    // EArrest statuses => REMOVING (CANCELLING) state.
    [ArrestStatus.IN_ARREST_CANCEL]: CaseArrestsFilterStates.REMOVING,
    // EArrest statuses => REMOVED (CANCELLED) state.
    [ArrestStatus.REMOVED]: CaseArrestsFilterStates.REMOVED,
    [ArrestStatus.CANCEL_SUCCESS]: CaseArrestsFilterStates.REMOVED,
    // EArrest statuses => UPDATING state.
    [ArrestStatus.IN_ARREST_CHANGE]: CaseArrestsFilterStates.UPDATING,
    // TAPAIS arrests fall back to 'draft' when CREATING of arrest failed.
    // So EArrest 'inactive' status falls best under "arrest has not been set".
    [ArrestStatus.INACTIVE]: CaseArrestsFilterStates.DRAFT,

    // EArest or PK arrests for which a request could not be sent successfully.
    [ArrestStatus.ARREST_APPLY_ERROR]: CaseArrestsFilterStates.ERROR_ON_REQUEST,
    // NB: "Change error" should also equal to "arrest is still active" in final filter!
    [ArrestStatus.ARREST_CHANGE_ERROR]: CaseArrestsFilterStates.ERROR_ON_REQUEST,
    // NB: "Cancel error" should also equal to "arrest is still active" in final filter!
    [ArrestStatus.ARREST_CANCEL_ERROR]: CaseArrestsFilterStates.ERROR_ON_REQUEST,

    // EArest arrests statuses where bank responded with some reason why arrest action could not be done.
    [ArrestStatus.ARREST_FAIL_OTHER]: CaseArrestsFilterStates.ERROR_FROM_BANK,
    [ArrestStatus.BANKRUPT]: CaseArrestsFilterStates.ERROR_FROM_BANK,
    [ArrestStatus.MISSING_DATA]: CaseArrestsFilterStates.ERROR_FROM_BANK,
    [ArrestStatus.CANCEL_ERROR_CANCELLED]: CaseArrestsFilterStates.ERROR_FROM_BANK,
    [ArrestStatus.CANCEL_ERROR_FULFILLED]: CaseArrestsFilterStates.ERROR_FROM_BANK,
    [ArrestStatus.CANCEL_ERROR_OTHER]: CaseArrestsFilterStates.ERROR_FROM_BANK,
  };

// Flipping "[earest_status]: arest_state" into "[arest_state]: earest_statuses[]".
// In the original map the statuses are as keys, to ensure we are covering all EArrest statuses.
export const ArrestStateToArrestStatuses: Record<
  CaseArrestsFilterState,
  ArrestStatus[]
> = Object.entries(EArrestStatusToArrestState).reduce(
  (
    stateToStatuses: Record<CaseArrestsFilterState, ArrestStatus[]>,
    [status, state]
  ) => {
    stateToStatuses[state] = [
      ...stateToStatuses[state],
      // In reality statuses here are set as ArrestStatus enum values,
      // but typescript detects object keys as only strings.
      status as unknown as ArrestStatus,
    ];

    return stateToStatuses;
  },
  {
    [CaseArrestsFilterStates.ACTIVE]: [],
    [CaseArrestsFilterStates.CREATING]: [],
    [CaseArrestsFilterStates.DRAFT]: [],
    [CaseArrestsFilterStates.REMOVED]: [],
    [CaseArrestsFilterStates.REMOVING]: [],
    [CaseArrestsFilterStates.UPDATING]: [],
    [CaseArrestsFilterStates.LEGACY]: [],
    [CaseArrestsFilterStates.ERROR_ON_REQUEST]: [],
    [CaseArrestsFilterStates.ERROR_FROM_BANK]: [],
  }
);
